
import { Component, Vue } from 'nuxt-property-decorator'
// import { Severity } from '@sentry/types'

@Component({
  fetchOnServer: false
})
export default class extends Vue {
  successfulSubscription: boolean = false
  unsubscribeSubscription: boolean = false
  isClickSubscribe: boolean = false

  async fetch () {
    await this.$fire.messagingReady()
  }

  subscribe () {
    this.isClickSubscribe = true
    this.$webPush.getPermission()
      .then(async (token) => {
        await this.$axios.patch(process.env.API_ENDPOINT + '/api/v3/user', {
          fcm_token: token.web_push_token
        })
      })
      .then(() => {
        this.successfulSubscription = true
      })
      .catch((err) => {
        this.unsubscribeSubscription = true
        // this.$sentry.setExtra('data', err)
        // this.$sentry.captureMessage('Web-push error', Severity.Warning)
      })
  }
}
