
import { Component, Vue, Watch } from 'nuxt-property-decorator'
import { Context } from '@nuxt/types'
import { Painting } from '~/types/dto/Painting'
import WebPushPopup from '~/components/popups/WebPushPopup.vue'
import BuyPainting from '~/components/buy/BuyPainting.vue'
import IntroPopup from '~/components/popups/IntroPopup.vue'
import CustomSwiper from '~/components/CustomSwiper.vue'
import AmpCustomSwiper from '~/components/amp/AmpCustomSwiper.vue'
import updateRatingChoicePainting from '~/util/update-rating-choice-painting'

@Component({
  // @ts-ignore
  amp: 'hybrid',
  ampLayout: 'amp',
  components: {
    AmpCustomSwiper,
    CustomSwiper,
    IntroPopup,
    WebPushPopup,
    BuyPainting
  },
  name: 'main-page'
})
export default class extends Vue {
  showWebPush: boolean = false
  alreadyUsedPowers: number[] = []
  activeIndex: number = 0
  paintings!: Painting[]
  stopLoad: boolean = false
  painting: {}
  isShowBuyPainting: boolean = false

  head () {
    return {
      title: this.$t('head.title.index'),
      meta: [
        { hid: 'description', name: 'description', content: this.$t('head.description.index') },
        { hid: 'og:title', name: 'og:title', content: this.$t('head.title.index') },
        { hid: 'og:description', name: 'og:description', content: this.$t('head.description.index') },
        { hid: 'og:image', name: 'og:image', content: require('~/assets/img/logo-black.svg') },
        { hid: 'og:type', name: 'og:type', content: 'website' },
        { hid: 'og:url', name: 'og:url', content: process.env.API_ENDPOINT + this.$route.fullPath }
      ]
    }
  }

  async asyncData (context: Context) {
    const paintings: Painting[] = await context.$axios.get(
      process.env.API_ENDPOINT + '/api/v3/paintings/web-list?limit=20',
      {
        // @ts-ignore
        requireToken: false,
        // @ts-ignore
        cache: true
      }
    ).then(response => response.data)
    return {
      paintings: paintings.map(item => ({
        id: item.id,
        width: item.width,
        height: item.height,
        ['title_' + context.i18n.locale]: item['title_' + context.i18n.locale],
        ['additional_info_' + context.i18n.locale]: item['additional_info_' + context.i18n.locale],
        artist: item.artist,
        rate: item.rate,
        user_current_rate: null,
        original_file: item.original_file,
        watermark_file: item.watermark_file,
        wear_merchandise: item.wear_merchandise,
        webpack_file: item.webpack_file
      }))
    }
  }

  mounted () {
    this.$eventBus.$emit('SlidePainting', this.paintings[0])

    this.$eventBus.$on('isShowBuyPainting', (isShowBuyPainting) => {
      this.isShowBuyPainting = isShowBuyPainting
    })
    this.$eventBus.$on('painting', (painting) => {
      this.painting = painting
    })

    this.$eventBus.$on('isShowSwiper', (isShowSwiper) => {
      this.isShowBuyPainting = isShowSwiper
    })

    this.$eventBus.$on('updateRate', (data) => {
      updateRatingChoicePainting(this.paintings, data.id, data.rate)
    })
  }

  @Watch('activeIndex')
  onIndexChange () {
    const pow = +(Math.log(this.activeIndex) / Math.log(3)).toFixed(2)
    if (pow % 1 === 0 && pow !== 0 && !this.alreadyUsedPowers.includes(pow)) {
      this.alreadyUsedPowers.push(pow)
      this.showWebPush = this.$webPush.checkPermission() === 'default'
    }
  }

  onSlide (index: number) {
    this.$eventBus.$emit('SlidePainting', this.paintings[index])

    if (this.activeIndex >= this.paintings.length - 5 && !this.stopLoad) {
      this.stopLoad = true
      this.$axios.get(process.env.API_ENDPOINT + '/api/v3/paintings/list', {
        // @ts-ignore
        requireToken: true,
        // @ts-ignore
        cache: false
      }).then((response) => {
        this.paintings.push(...response.data)
        this.stopLoad = false
      })
    }
  }
}
