import { render, staticRenderFns } from "./index.vue?vue&type=template&id=a4c81ac0&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=a4c81ac0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4c81ac0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BuyPainting: require('/var/www/arthall/arthall.front/components/buy/BuyPainting.vue').default,CustomSwiper: require('/var/www/arthall/arthall.front/components/CustomSwiper.vue').default,AmpCustomSwiper: require('/var/www/arthall/arthall.front/components/amp/AmpCustomSwiper.vue').default})
