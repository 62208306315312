import { Painting } from '~/types/dto/Painting'

/**
 * @param { Painting[]} paintings - list paintings
 * @param {number} id - id current painting
 * @param {number} changeField - rate current painting
 */
function updateRatingChoicePainting (paintings: Painting[], id: number, changeField: number) {
  paintings.map((item: Painting) => {
    if (item.id === id) {
      item.user_current_rate = changeField
      return item
    }
    return item
  })
}

export default updateRatingChoicePainting
