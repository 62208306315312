
import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  showPopup: boolean = true
  loaded: boolean = false

  beforeMount () {
    this.showPopup = !JSON.parse(localStorage.getItem('intro') || 'false')
  }

  mounted () {
    this.loaded = true
  }

  closeIntro () {
    localStorage.setItem('intro', 'true')
    this.showPopup = false
  }
}
